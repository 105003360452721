<template>
    <content-view v-if="isPageLoaded">
        <div class="course content-centered">
            <div
                class="course__content"
                v-if="course && course.status"
                :class="{ 'course__admin' : isEditorCourse  && course.access_edit}"
            >
                <div class="course__head">
                    <div class="course__toolbar" v-if="isEditorCourse || course.access_results">
                        <div v-if="isEditorCourse"
                            class="toolbar__status"
                            :class="{
                    'toolbar__status--active': course.status.id === 0,
                    'toolbar__status--draft': course.status.id === 1,
                    'toolbar__status--complete': course.status.id === 2,
                }"
                        >
                            {{ course.status.name }}
                        </div>
                        <div class="toolbar__break"></div>
                        <router-link v-if="course.results" tag="div" :to="`/courses/${course.id}/results/`" class="toolbar__results">
                            <span class="toolbar__results-title">Посмотреть результаты</span>
                            <span class="toolbar__results-icon" v-if="course.new_finished_count > 0"
                                v-tooltip="{ content: 'Новые сотрудники, завершившие курс' }"
                            >{{ course.new_finished_count }}</span
                            >
                        </router-link>

                        <router-link
                            v-if="isEditorCourse"
                            tag="div"
                            :to="course.access_edit ? `/courses/${course.id}/edit/` : ''"
                            v-tooltip="!course.access_edit ? {content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7} : null"
                            class="toolbar__button toolbar__edit"
                        >
                            <i>
                                <icon height="12" width="12">
                                    <path
                                        d="M0 9.49978V12.0001H2.50035L9.87802 4.62242L7.37767 2.12207L0 9.49978Z"
                                    />
                                    <path
                                        d="M11.8045 1.7519L10.2476 0.195027C9.98759 -0.065009 9.56419 -0.065009 9.30415 0.195027L8.08398 1.4152L10.5843 3.91554L11.8045 2.69537C12.0646 2.43534 12.0646 2.01193 11.8045 1.7519Z"
                                    />
                                </icon>
                            </i>
                        </router-link>
                        <div
                            v-if="isEditorCourse"
                            class="toolbar__button toolbar__delete"
                            @click="removeCourse"
                            v-tooltip="!course.access_edit ? {content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7} : null"
                        >
                            <i>
                                <icon height="12" width="12">
                                    <path
                                        d="M9.96776 1.2037H7.82428V0.391312C7.82428 0.175219 7.64909 0 7.43297 0H4.56673C4.35063 0 4.17541 0.175195 4.17541 0.391312V1.2037H2.03194C1.81584 1.2037 1.64062 1.3789 1.64062 1.59502V2.7987C1.64062 3.01479 1.81582 3.19001 2.03194 3.19001H9.96771C10.1838 3.19001 10.359 3.01481 10.359 2.7987V1.59502C10.359 1.37888 10.1839 1.2037 9.96776 1.2037ZM7.04163 1.2037H4.95804V0.782602H7.04163V1.2037Z"
                                    />
                                    <path
                                        d="M2.18457 3.97266L2.50904 11.6254C2.5179 11.8348 2.69033 12.0001 2.89998 12.0001H9.10079C9.31044 12.0001 9.48287 11.8348 9.49173 11.6254L9.81617 3.97266H2.18457ZM4.81749 10.4088C4.81749 10.6249 4.6423 10.8001 4.42618 10.8001C4.21009 10.8001 4.03487 10.6249 4.03487 10.4088V5.56392C4.03487 5.34783 4.21006 5.17261 4.42618 5.17261C4.64227 5.17261 4.81749 5.34781 4.81749 5.56392V10.4088ZM6.39162 10.4088C6.39162 10.6249 6.21643 10.8001 6.00031 10.8001C5.7842 10.8001 5.609 10.6249 5.609 10.4088V5.56392C5.609 5.34783 5.7842 5.17261 6.00031 5.17261C6.21643 5.17261 6.39162 5.34781 6.39162 5.56392V10.4088ZM7.96576 10.4088C7.96576 10.6249 7.79056 10.8001 7.57445 10.8001C7.35833 10.8001 7.18313 10.6249 7.18313 10.4088V5.56392C7.18313 5.34783 7.35833 5.17261 7.57445 5.17261C7.79056 5.17261 7.96576 5.34781 7.96576 5.56392V10.4088Z"
                                    />
                                </icon>
                            </i>
                        </div>
                    </div>
                    <new-editor
                            v-if="Object.values(course).length !== 0"
                            :data="course.text"
                            :editable="false"
                            top-placeholder="Название курса"
                            bottom-placeholder="Описание курса"
                            id="id_text"                            
                    />
                    <button v-if="isEditorCourse" @click="changePermission" class="course__btn">Назначить</button>
                    <button v-else-if="!isEditorCourse && !course.started && course.access" class="course__btn" @click="startLearning">Начать обучение</button>
                </div>
                <div class="course__body">
                    <div class="course__index">Программа курса</div>
                    <course-module
                        v-for="(module, index) in course.modules"
                        :key="`module-${module.id}`"
                        :module="module"
                        :course="course"
                        :module-index="index+1"
                        :isEditorCourse="isEditorCourse"
                        :accessEdit="course.access_edit"
                        :after-delete-module="afterDeleteModule"
                        @on-sort="sortModules"
                    ></course-module>
                </div>
                <button v-if="isEditorCourse && course.access_edit" @click="moduleAddModal" class="course__btn course__btn--add">
                    <span>Добавить учебный модуль</span>
                </button>
                <button v-else-if="!isEditorCourse && !course.started && course.access" class="course__btn" @click="startLearning">Начать обучение</button>
            </div>
        </div>
    </content-view>
</template>

<script>
import session from '@/api/session'
import { mapGetters } from 'vuex'
import { eventBus } from '@/main'
import placeholders from 'assets/js/placeholders'
import NewEditor from '../../../components/editor/newEditor'

export default {
    name: 'CourseDetail',
    components: {
        NewEditor: () => import('@/components/editor/newEditor'),
        CourseModule : () => import('@/components/course/CourseModule'),
        Icon : () => import('@/components/icon/Icon'),
        ContentView : () => import('@/views/menu/ContentView'),
    },
    data () {
        return {
            isPageLoaded: false,
            course: {},
            placeholders
        }
    },
    computed: {
        ...mapGetters('default_data', ['isAdmin', 'isEditorCourse'])
    },
    created () {
        this.loadCourse()
    },
    methods: {
        async sortModules() {
            const data = this.course.modules.map(module => {
                let lessonsOrder = {};
                module.lessons.forEach((lesson, index) => {
                    lessonsOrder[lesson.id] = index+1;
                })
                return {
                    module_id: module.id,
                    lessons: lessonsOrder
                }
            })
            const request = await session.post(`api/v1/course/${this.course.id}/drag_and_drop/`, data);
            console.log(request)
        },
        async changePermission() {
            let checkbox = 'Для всей компании'
            if (this.course.permission) {
                const ModalPermissionUpdate = () => import(`@/components/permission/ModalPermissionUpdate`);
                this.$modal.show(ModalPermissionUpdate, {
                    title: 'Назначение курса',
                    checkbox: checkbox,
                    text: 'Выберите отделы, должности или сотрудников, которым хотите назначить обучение на курсе',
                    permissionType: 'course',
                    permissionObjectID: this.course.id,
                }, {
                    name: 'ModalPermissionUpdate',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': (data) => {
                        if (data.params) {
                            eventBus.$emit('reload-course', {});
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Данные изменены',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        }
                    }
                })
            } else {
                const ModalPermissionCreate = () => import(`@/components/permission/ModalPermissionCreate`);
                this.$modal.show(ModalPermissionCreate, {
                    title: 'Назначение курса',
                    checkbox: checkbox,
                    defaultData: {'structure': {}, 'users': [], 'access_all': false, 'note': {'parentRight': false}},
                    text: 'Выберите отделы, должности или сотрудников, которым хотите назначить обучение на курсе',
                }, {
                    name: 'ModalPermissionCreate',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': (data) => {
                        if (data.params) {
                            let url = '/api/v1/permission/';
                            url += '?permission_type=course';
                            url += '&permission_object_id=' + this.course.id;
                            session
                                .post(url, data.params.data)
                                .then(response => {
                                    this.$swal({
                                        title: 'Отлично!',
                                        text: 'Данные изменены',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1400,
                                    });
                                    this.course.permission = true;
                                    eventBus.$emit('reload-course', {});
                                })
                                .catch(() => {

                                });
                        }
                    }
                })
            }
        },
        async loadCourse () {
            try {
                const response = await session.get(`/api/v1/course/${this.$route.params.course_id}/`)
                this.course = response.data
            } catch (err) {
                if (err.response.status === 404) {
                    this.$router.push(`/404`)
                }
            }
        },
        async startLearning () {
            if (!this.course.started) {
                try {
                    const request = await session.post(`/api/v1/course/${this.course.id}/start/ `)
                    this.loadCourse();
                } catch(err) {

                }
            }
        },
        async addModule (name) {
            const request = await session.post(`/api/v1/course-module/`, {
                name: name,
                course: this.course.id
            })
            this.loadCourse()
        },
        afterDeleteModule(id) {
            this.course.modules = this.course.modules.filter(module => module.id !== id);
        },
        removeCourse () {
            if (this.course.access_edit) {
                eventBus.$emit('remove-course', this.course)
            }
        },
        removeLesson (data) {
            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-lg btn-alt-success m-5',
                    cancelButton: 'btn btn-lg btn-alt-danger m-5'
                },
                title: 'Удалить урок?',
                text: data.name,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Да, удалить!',
                cancelButtonText: 'Отменить',
            }).then(async value => {
                if (value.value) {
                    let swal = this.$swal({
                        title: 'Удаление...',
                        icon: 'info',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                    })
                    try {
                        await session.delete(`/api/v1/course-lesson/${data.id}/`)
                        swal.close()
                        this.$swal({
                            title: 'Отлично',
                            text: 'Урок удален',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400,
                        })
                        this.loadCourse();
                    } catch (err) {
                        console.log(err)
                        swal.close()
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'При обработке запроса произошла ошибка на сервере',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400,
                        })
                    }
                }
            })
        },
        removeTest (data) {
            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-lg btn-alt-success m-5',
                    cancelButton: 'btn btn-lg btn-alt-danger m-5'
                },
                title: 'Удалить тест?',
                text: data.name,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Да, удалить!',
                cancelButtonText: 'Отменить',
            }).then(async value => {
                if (value.value) {
                    let swal = this.$swal({
                        title: 'Удаление...',
                        icon: 'info',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                    })
                    try {
                        await session.delete(`/api/v1/course-test/${data.id}/`)
                        swal.close()
                        this.$swal({
                            title: 'Отлично',
                            text: 'Тест удален',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400,
                        })
                        this.loadCourse();
                    } catch (err) {
                        console.log(err)
                        swal.close()
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'При обработке запроса произошла ошибка на сервере',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400,
                        })
                    }
                }
            })
        },
        moduleAddModal () {
            const ModuleAdd = () => import('@/components/course/modals/ModuleAdd')
            this.$modal.show(
                ModuleAdd,
                {
                    moduleId: 1,
                    onAdd: this.addModule
                },
                {
                    name: 'module-add',
                    adaptive: true,
                    maxWidth: 528,
                    width: '100%',
                    height: 'auto',
                }
            )
        },
    },
    mounted () {
        eventBus.$on('reload-course', data => {
            this.loadCourse();
            console.log('reload-course');
        })
        this.$nextTick(() => {
            this.isPageLoaded = true;
        })
        eventBus.$on('remove-lesson', data => {
            this.removeLesson(data);
        })
        eventBus.$on('remove-test', data => {
            this.removeTest(data);
        })
    }
}
</script>

<style lang='scss' scoped>
    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;
        border-radius: 4px;

        p {
            margin-bottom: 0 !important;
        }

        td, th {
            min-width: 1em;
            border: 2px solid #E6EBF3;
            padding: 10px 15px;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;
            > * {
                margin-bottom: 0;
            }
        }

        th {
            font-weight: 600;
            text-align: left;
        }

        tr:first-child {
            font-weight: 600;
            text-align: left;
        }
    }
</style>

<style lang="scss">
@import "#sass/v-style";
@import "detail";

.content-centered {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    height: 100%;

    @include below($tablet) {
        max-width: 100%;
    }
}

.course {
    color: $black;
    font-style: normal;

    ul,
    ol {
        padding-left: 1rem !important;
    }

    &__content {
        padding-top: 56px;
        padding-bottom: 90px;

        ul {
            list-style: disc;
        }

        @include below($tablet) {
            padding: 30px 18px 48px 10px;
        }

        @include below($mobile) {
            padding: 20px 8px;
        }
    }

    &__head {
        h1 {
            font-weight: bold;
            font-size: 40px;
            line-height: 52px;

            @include below($mobile) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &__toolbar {
        display: flex;
        align-items: center;
        margin-top: 24px;

        @include below($mobile) {
            flex-wrap: wrap;
        }
    }

    .toolbar {
        &__status {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            padding: 7px 14px;
            margin-right: 16px;
            border-radius: 40px;

            &--active {
                background: #c8f5d8;
            }

            &--draft {
                background: #f2f2f2;
            }

            &--complete {
                background: #d5e8ff;
            }
        }

        &__results {
            display: flex;
            align-items: center;

            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            background: #ffffff;
            border: 1px solid #fbc04f;
            border-radius: 40px;
            padding: 6px 13px;
            position: relative;
            margin-right: 8px;
            cursor: pointer;

            @include below($mobile) {
                margin-left: unset;
                order: 2;
                margin-right: auto;
                margin-top: 24px;
            }

            &-icon {
                position: relative;
                top: 1px;
                width: 21px;
                height: 25px;
                padding-top: 2px;
                margin-left: 8px;

                text-align: center;
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='26' viewBox='0 0 21 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.5L21 0.5L21 19.3182L10.5 25.5L0 19.3182L0 0.5Z' fill='%23FBC04F'/%3E%3C/svg%3E%0A");
            }
        }

        &__break {
            display: none;

            @include below($mobile) {
                display: block;
                flex-basis: 100%;
                height: 0;
                order: 2;
            }
        }

        &__button {
            display: flex;
            cursor: pointer;
            margin: 0 0 0 12px;
            border: 2px solid #e6ebf3;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            padding-left: 8px;

            svg {
                color: #989cae;
                transition: all $ease;
            }
        }

        &__edit {
            &:hover {
                svg {
                    color: $gold;
                }
            }
        }

        &__delete {
            &:hover {
                svg {
                    color: $red-main;
                }
            }
        }
    }

    &__preview-text {
        margin-top: 24px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        @include below($mobile) {
            font-size: 14px;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__btn {
        margin-top: 24px;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        padding: 7px 30px;
        background: #fbc04f;
        border-radius: 40px;
        border: none;
        transition: all $ease;
        cursor: pointer;

        &--add {
            width: 33px;
            height: 33px;
            position: relative;
            padding: 0;
            vertical-align: middle;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1V11' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 6H11' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;

            span {
                position: absolute;
                width: max-content;
                top: 0;
                line-height: 33px;
                left: 45px;
            }
        }

        &--outline {
            margin-top: 0;
            margin-right: 10px;
            background: transparent;
            border: 1.5px solid $gold;

            &:hover {
                background: $gold;
            }
        }
    }

    &__body {
        margin-top: 76px;

        @include below($tablet) {
            margin-top: 64px;
        }

        @include below($mobile) {
            margin-top: 60px;
        }
    }

    &__index {
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 12px;

        @include below($mobile) {
            font-size: 18px;
        }
    }

    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        padding: 33px 0;
        border-top: 1px solid #c5cdd9;
        position: relative;

        &:first-of-type {
            border-top: none;
        }

        //orange btn

        &--active {
            @include below($mobile) {
                flex-direction: column;
            }

            .item-action {
                @include below($mobile) {
                    margin-top: 12px;
                    margin-left: 36px;
                    margin-right: auto;
                }

                button {
                    color: $black;
                    background: #fbc04f;
                    cursor: pointer;
                }
            }

            .course__test-action {
                @include below($mobile) {
                    margin-left: 50px;
                }
            }
        }

        //grey btn

        &--active ~ &--active {
            .item-action {
                button {
                    color: #9d9d9e;
                    background: #f2f2f2;
                    cursor: default;
                }
            }
        }

        //green btn

        &--complete {
            .item-action {
                border-radius: 50px;
                font-size: 0;
                background-color: #50b440;
                min-width: 24px;
                min-height: 24px;
                width: 24px;
                height: 24px;
                padding: 0;
                vertical-align: middle;
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6L5.01958 10L14.0636 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
                background-position: center;
                background-repeat: no-repeat;
                cursor: unset;
            }
        }

        &-menu {
            background: #ffffff;
            box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 14px 16px;
            position: absolute;
            right: -18px;
            z-index: 1;

            .menu-item {
                margin: 5px 0;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;

                &--delete {
                    color: #ff0000;
                }
            }
        }
    }

    //   admin section

    &__admin {
        .course__lesson {
            &-dragicon {
                cursor: move;
                @include below($mobile) {
                    display: none;
                }
                margin-top: -2px;

                svg {
                    color: #989cae;
                    transition: all $ease;
                }
            }

            &-title {
                cursor: pointer;
                margin-left: 48px;
                word-break: break-word;
                @include below($mobile) {
                    margin-left: 36px;
                }
            }

            &-index {
                left: 25px;
                @include below($mobile) {
                    left: 0;
                }
            }
        }

        .course__item {
            @include below($tablet) {
                flex-direction: row;
            }
        }

        .item-admin-action,
        .module-admin-action {
            svg {
                color: #989cae;
                transition: all $ease;
                @include below($mobile) {
                    transform: rotate(90deg);
                    margin-right: -6px;
                }
            }

            &:hover,
            &--active {
                cursor: pointer;

                svg {
                    color: $gold-main;
                }
            }
        }

        .module-admin-action {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
.lesson blockquote, .course blockquote {
    border-left: 6px solid #fbc04f;
    color: #000;
    font-weight: 600;
    padding-left: 28px;
    font-style: normal;
}
</style>
